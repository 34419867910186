<template>
<div class="p-grid">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h3 style="color:#ffcc2a;">{{$t('Cotización de Pólizas')}}</h3>
            <div class="p-grid" >
                <div class="p-col-12 p-md-12">
                    <div class="feature-card">
                        <DataTable :value="poliza_vehiculo" stripedRows responsiveLayout="scroll">
                            <template #header>
                                {{$t('Vehicle Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.evaluacion.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.evaluacion.tipopoliza_name}}</div>
									<Avatar icon="bi bi-shield-shaded" class="p-mr-2" size="large" style="background-color:gray; color: #ffffff" v-if="slotProps.data.estado==7"/>
									<div v-if="slotProps.data.estado==7">
										{{$t('Proceso Renovación')}}
									</div>
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}                              
                                </template>
                            </Column> 
                            <Column field="id" :header="$t('Nº')" headerStyle="width: 120px">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
							<Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 110px;" type="button" :label="$t('Vehículo')" @click="Ver1(1,slotProps.data)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" style="width: 110px;" type="button" :label="$t('Licencia')" @click="Ver1(2,slotProps.data.licencias)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column :header="$t('Nombre Completo')" field="nombre">
                                <template #body="slotProps">
									<div>
										{{slotProps.data.nombre}}
									</div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
									{{moneda(slotProps.data.evaluacion.montofinal/slotProps.data.evaluacion.cuotas)}}
								</template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
									{{moneda(slotProps.data.evaluacion.montofinal)}}
									<br><br>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2 p-mb-2" @click="Ver(slotProps.data)"/>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2 p-mb-2" @click="EditPoliza(slotProps.data)" v-if="edit"/>
									<!--<Button :disabled ="$store.state.loading" @click="confirm(slotProps.data)" icon="pi pi-times" :label="$t('Anular')" class="p-button-danger p-mr-2 p-mb-2"></Button>-->
                                </template>
                            </Column>
							<Column field="vb" :header="$t('Revisado')">
                                <template #body="slotProps">
									<InputSwitch v-model="slotProps.data.evaluacion.rvb" :disabled="!del" @change="Revisado(slotProps.data)"/>
								</template>
                            </Column>
                        </DataTable>
                    </div>
                </div>

          </div>
        </div>
	</div>

	<Dialog v-model:visible="Dialog" :style="{width: size}" :header="$t('Vehicle Insurance')" :modal="true" :maximizable="true">
		<br>
        <div class="confirmation-content">
            <div class="p-field">
                <h4>{{$t('Información Básica')}}</h4>
				<div class="p-grid">
					<div class="p-col-3">
						{{$t('Fecha')}}: <strong>{{poliza.fecha}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Número ID')}}: <strong>{{poliza.code}}</strong>			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'es' && poliza.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{poliza.sexnombre}}</strong> 			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'en' && poliza.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{poliza.sexname}}</strong> 			
					</div>
					<div class="p-col-6">
						{{$t('Nombre Completo')}}: <strong>{{poliza.nombre}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Correo')}}: <strong>{{poliza.correo}}</strong>			
					</div>
					<div class="p-col-4" v-if="poliza.tipocliente==1">
						{{$t('Fecha de nacimiento')}}: <strong>{{poliza.fechanac}}</strong>			
					</div>
					<div class="p-col-2" v-if="poliza.tipocliente==1">
						{{$t('Edad')}}: <strong>{{poliza.edad}}</strong>			
					</div>
					<div class="p-col-6" v-if="i18n.locale() == 'es'">
						{{$t('Nacionalidad')}}: <strong>{{poliza.nacionalidad}}</strong> 			
					</div>
					<div class="p-col-6" v-else>
						{{$t('Nacionalidad')}}: <strong>{{poliza.nationality}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Localidad')}}: <strong>{{poliza.localidadn}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono móvil')}}: <strong>{{poliza.celular}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono privado')}}: <strong>{{poliza.tlflocal}}</strong>			
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Información del Vehículo')}}</h4>
				<div class="p-grid">
					<div class="p-col-12" v-if="i18n.locale() == 'es'">
						{{$t('Tipo de Vehículo')}}: <strong>{{poliza.tipovehnombre}}</strong> 			
					</div>
					<div class="p-col-12" v-else>
						{{$t('Tipo de Vehículo')}}: <strong>{{poliza.tipovehname}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Marca')}}: <strong>{{poliza.marca}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Módelo')}}: <strong>{{poliza.modelo}}</strong>			
					</div>
					<div class="p-col-4">
						<!--
						<label for="state">{{$t('Año de construcción')}} ({{poliza.edadveh}})</label><br>
						<Dropdown v-model="poliza.veh_ano" :options="years" optionLabel="nombre" placeholder="...?" @change="Calcular()" disabled></Dropdown>		
						-->
					</div>
					<div class="p-col-4">
						{{$t('Año de Vehículo')}}: <strong>{{poliza.veh_compra}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Automático')}} <i class="pi pi-check" v-if="poliza.veh_automatico"></i> <i class="pi pi-times" v-else></i>			
					</div>
					<div class="p-col-4">
						{{$t('Número de chasis')}}: <strong>{{poliza.veh_chasis}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Nº placa')}}: <strong>{{poliza.veh_placa}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Nº asientos')}}: <strong>{{poliza.veh_asientos}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Color')}}: <strong :style="'background: #'+poliza.veh_color+';color:#'+poliza.veh_color+';'">{{poliza.veh_color}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Posición del Volante')}}: <strong>{{poliza.veh_volante}}</strong>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>Keuringskaart</h4>
				<div class="p-grid">
					<div class="p-col-4">
						{{$t('Registro')}}:<strong>{{poliza.cardreg}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Fecha de emisión')}}:<strong>{{poliza.cardemi}}</strong>
					</div>
					<div class="p-col-4">
						<img style="width: 100px;" :src="url.upload+poliza.veh_adverso" @click="toggle" v-if="poliza.veh_adverso"/>
						<FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="codimg" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(2,poliza)" :auto="true" v-if="poliza.veh_adverso"/>
						<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
							<img style="width: 700px;" :src="url.upload+poliza.veh_adverso"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Información de la Licencia')}}</h4>
				<div class="p-grid">
					<div class="p-col-12">
						<DataTable :value="poliza.licencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Imagen')">
								<template #body="slotProps">
									<img style="width: 50px;" :src="url.upload+slotProps.data.adverso" @click="IMG(url.upload+slotProps.data.adverso)" v-if="slotProps.data.adverso"/>
									<FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="codimg" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(1,slotProps.data)" :auto="true" v-if="slotProps.data.adverso"/>
								</template>
							</Column>
							<Column :header="$t('Licencia')">
								<template #body="slotProps">
									{{slotProps.data.categoria}}
								</template>
							</Column>
							<Column :header="$t('Número ID')">
								<template #body="slotProps">
									{{slotProps.data.code}}
									<Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
								</template>
							</Column>
							<Column :header="$t('Nombre Completo')">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column :header="$t('Fecha de emisión')">
								<template #body="slotProps">
									{{convfech(slotProps.data.emision)}}
								</template>
							</Column>
							<Column :header="$t('Fecha de caducidad')">
								<template #body="slotProps">
									{{convfech(slotProps.data.caducidad)}}
								</template>
							</Column>
							<Column :header="$t('País de emisión')">
								<template #body="slotProps" v-if="i18n.locale() == 'es'">
									{{slotProps.data.pais_nombre}}
								</template>
								<template #body="slotProps" v-else>
									{{slotProps.data.pais_name}}
								</template>
							</Column>
						</DataTable>
						<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
							<img style="width: 350px;" :src="imagen"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Información Adicional')}}</h4>
				<div class="p-grid">
					<div class="p-col-12">
						{{$t('¿Tiene alguna discapacidad / enfermedad física o de otro tipo?')}}: <strong v-if="poliza.preguntas.pregunta1">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Has sido condenada penalmente?')}}: <strong v-if="poliza.preguntas.pregunta2">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Alguna vez le han negado el seguro?')}}: <strong v-if="poliza.preguntas.pregunta3">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Existen circunstancias circundantes que sean importantes para aceptar este seguro?')}}: <strong v-if="poliza.preguntas.pregunta4">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿O se imponen condiciones agravadas?')}}: <strong v-if="poliza.preguntas.pregunta5">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Descuento por no siniestralidad %?')}}: <strong v-if="poliza.preguntas.noclain">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-4" v-if="poliza.preguntas.noclain">
						<img style="width: 100px;" :src="url.upload+poliza.preguntas.document" @click="toggle2" />
						<OverlayPanel ref="op2" appendTo="body" :showCloseIcon="true">
							<img style="width: 700px;" :src="url.upload+poliza.preguntas.document"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Evaluación')}}</h4>
                <h5 v-if="i18n.locale() == 'es'">{{$t('Tipo de Póliza')}}: <strong>{{poliza.evaluacion.tipopoliza_name}}</strong></h5>
                <h5 v-else>{{$t('Tipo de Póliza')}}: <strong>{{poliza.evaluacion.tipopoliza_name}}</strong></h5>  
				<!--
				<div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-4">
                        <Checkbox v-model="poliza.exoneracion" :binary="true"  :disabled="poliza.evaluacion.rvb"/> <strong>{{$t('Exoneracion')}}</strong><br><br>
                    </div>
				</div>
				-->   
				<div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-4">
						<label for="state">{{$t('Reaseguro')}}</label>
						<Dropdown v-model="poliza.evaluacion.reinsurance_id" :options="reinsurances" :optionLabel="$t('name')" placeholder="Select..." @change="Calcular()"  :disabled="poliza.evaluacion.rvb"></Dropdown>
					</div>
                    <div class="p-col-4" v-if="poliza.estado!=7">
						<label for="state">{{$t('Referidos')}}</label>
						<Dropdown v-model="poliza.reseller_id" :options="sellers.filter(val => val.tipopoliza == poliza.evaluacion.tipopoliza)" optionLabel="seller_name" placeholder="Select..." @change="Calcular()"  :disabled="poliza.evaluacion.rvb"></Dropdown>
					</div>
					<br>
				</div>
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-col-3">
						<label for="state">{{$t('Tarifa')}}: 
						<strong v-if="calculo">
							{{calculo.tarifa[$t('name')]}}
						</strong>	
					</label>
						<!--<Dropdown v-model="poliza.tarifa" :options="tarifas" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calcular()"  disabled></Dropdown>-->
					</div>
					<div class="p-col-2">
						<label>{{$t('Costo')}}</label>
						<InputText v-model="this.poliza.evaluacion.kosten" type="number" min="0" disabled/>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Descuento Especial')}}</label>
						<Dropdown v-model="poliza.descuento" :options="descuentos" optionLabel="nombre" placeholder="Select..."  :disabled="poliza.evaluacion.rvb"  @change="Estimate()"></Dropdown>
					</div>	
					<div class="p-col-2">
						<label for="state">{{$t('Sin reclamación')}} ({{ no_claim.year }} {{$t('años')}})</label>
						<div>{{ no_claim.noclaim }}%</div>
					</div>	
					<div class="p-col-2">
						<label>No claim years</label>
						<InputText v-model="antiquity" type="number" min="0" @change="Calc_NoClaim()" :disabled="poliza.evaluacion.rvb"/>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Duración')}}</label>
						<Dropdown v-model="poliza.duracion" :options="duracion" :optionLabel="$t('nombre')" placeholder="Select..." @change="Duracion()"  v-if="poliza.duracion"  :disabled="poliza.evaluacion.rvb"></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Pagos')}}</label>
						<Dropdown v-model="poliza.cuotas" :options="cuotas_opc" :optionLabel="$t('nombre')" placeholder="Select..."  :disabled="poliza.evaluacion.rvb" @change="Estimate()"></Dropdown>
					</div>	
					<div class="p-col-2">
						<label for="state">{{$t('Ajuste')}}</label>
						<div>{{moneda(parseFloat(poliza.evaluacion.adjustment).toFixed(2))}}</div>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Estimación')}}</label>
						<InputText v-model="estimate" type="number"  step="0.01" min="0" :max="monto" :disabled="poliza.evaluacion.rvb"/>
					</div>
					<div class="p-col-1" style="padding-top: 20px">
						<Button :disabled ="$store.state.loading || poliza.evaluacion.rvb" icon="pi pi-refresh" class="p-button-rounded p-button-primary" @click="Estimate()"/>
					</div>
					<div class="p-col-12">
						<label for="state">{{$t('Adicional')}}</label>
						<Button label="Clean up"  class="p-button-danger p-button-rounded p-button-text" v-if="adicional.length>0" @click="adicional=[]" v-tooltip.bottom="$t('Ninguna')" style="width: 30%;"/>
						<MultiSelect v-model="adicional" :options="adicionales" alls="All" :optionLabel="$t('nombre')"  placeholder="...?" class="multiselect-custom"  
							@change="Estimate()"  :disabled="poliza.evaluacion.rvb" v-if="no_claim.year>=4">
							<template #option="slotProps">
								<div class="country-item country-item-value">
									<div>{{ slotProps.option.name }} - {{moneda(parseFloat(slotProps.option.valor).toFixed(2))}}</div>
								</div>
								</template>				
						</MultiSelect>
						<MultiSelect v-model="adicional" :options="adicionales.filter(val => val.code != 5 && val.code != 6)" alls="All" :optionLabel="$t('nombre')"  placeholder="...?" class="multiselect-custom"  
							@change="Estimate()"  :disabled="poliza.evaluacion.rvb" v-else>
							<template #option="slotProps">
								<div class="country-item country-item-value">
									<div>{{ slotProps.option.name }} - {{moneda(parseFloat(slotProps.option.valor).toFixed(2))}}</div>
								</div>
								</template>				
						</MultiSelect>
					</div>	
					<div class="p-col-12">
						<label>{{$t('Observación')}}</label>
						<Textarea v-model="poliza.evaluacion.comentario" rows="2" :disabled="poliza.evaluacion.rvb"/>
					</div>
				</div>
            </div>
            <div class="p-field" v-if="calculo">
				<h4>{{$t('Cálculo Anual')}}</h4>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-col-6 p-grid">
						<div class="p-col-12">
							{{$t('Valor de Vehículo')}}: {{moneda(calculo.catalogo_vehiculo)}}
						</div>
						<div class="p-col-12">
							{{$t('Deducible')}}: {{moneda(calculo.deductible)}}
						</div>
						<div class="p-col-12">
							{{$t('Responsabilidad')}}: {{moneda(calculo.tarifa.valor)}}
						</div>						
					</div>
					<div class="p-col-6 p-grid">
						<div class="p-col-7">{{$t('Prima Bruta WA')}}</div>
						<div class="p-col-5">{{moneda(calculo.primabruta)}}</div>
						<div class="p-col-7">{{$t('Recargo Años del Vehículo')}}</div>
						<div class="p-col-5">{{moneda(calculo.recarga_veh)}}</div>
						<div class="p-col-7">{{$t('Recargo Edad Persona')}}</div>
						<div class="p-col-5">{{moneda(calculo.recarga_edad)}}</div>
						<div class="p-col-7">{{$t('Recargo Volante')}}</div>
						<div class="p-col-5">{{moneda(calculo.recarga_volante)}}</div>
						<div class="p-col-7">{{$t('Asientos')}}</div>
						<div class="p-col-5">{{moneda(calculo.adic_asientos)}}</div>
						<br><br>
						<div class="p-col-7"><strong>{{$t('Total')}} WA</strong></div>
						<div class="p-col-5"><strong>{{moneda(calculo.subtotal)}}</strong></div>
						<br><br>
						<div class="p-col-7">{{$t('Sin reclamación')}} WA</div>
						<div class="p-col-5">{{moneda(calculo.subtotal*no_claim.factor)}}</div>
						<div class="p-col-7">{{$t('Forma de pago')}}</div>
						<div class="p-col-5">{{moneda((calculo.subtotal*(1+no_claim.factor-poliza.descuento.valor)+adic)*parseFloat(poliza.cuotas.porcentaje/100))}} ({{poliza.cuotas.porcentaje}}%)</div>
						<div class="p-col-7">{{$t('Descuento Especial')}} WA</div>
						<div class="p-col-5">{{moneda(calculo.subtotal*poliza.descuento.valor)}}</div>
						<div class="p-col-7">{{$t('Adicional')}}</div>
						<div class="p-col-5">{{moneda(adic)}}</div>
					</div>
				</div>
            </div>
            <div class="p-field" v-if="calculo">
                <h4>{{$t('Resumen de Póliza')}}</h4>
                <div class="p-grid">
					<div class="p-col-6 p-grid">
						<div class="p-col-6">
							{{$t('Duración')}}: 
							<strong v-if="i18n.locale() == 'es'">{{poliza.duracion.nombre}}</strong>
							<strong v-else>{{poliza.duracion.name}}</strong> 
						</div>
						<div class="p-col-6">
							{{$t('Cuotas')}}: <strong>{{poliza.cuotas.valor}}</strong> 
						</div>
					</div>
					<div class="p-col-6 p-grid">
						<div class="p-col-7"><strong>{{$t('Premie WA')}}</strong></div>
						<div class="p-col-5">{{moneda((parseFloat(calculo.subtotal*(1+no_claim.factor-poliza.descuento.valor)+adic))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor)}}</div>
						<br><br>
						<div class="p-col-7"><strong>{{$t('Costo')}}</strong></div>
						<div class="p-col-5"><strong>{{moneda(poliza.evaluacion.kosten)}}</strong></div>
						<br><br>
						<div class="p-col-7"><strong>{{$t('OB')}}</strong></div>
						<div class="p-col-5">
							<strong>
								{{moneda(((parseFloat(calculo.subtotal*(1+no_claim.factor-poliza.descuento.valor)+adic))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor+parseFloat(poliza.evaluacion.kosten))*(calculo.impuesto))}}
							</strong>
						</div>
						<br><br>
						<div class="p-col-7"><h4><strong>{{$t('Monto')}}</strong></h4></div>
						<div class="p-col-5"><h4><strong>
							{{moneda(((parseFloat(calculo.subtotal*(1+no_claim.factor-poliza.descuento.valor)+adic))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor+parseFloat(poliza.evaluacion.kosten))*(1+calculo.impuesto))}}
						</strong></h4></div>
					</div>
				</div>
            </div>
		</div>
		<template #footer>
			<div v-if="poliza.evaluacion.rvb">
				{{$t('Estatus')}}:
				<Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="renovacion"></Dropdown>
				<Dropdown v-model="poliza.estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-else></Dropdown>
				<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="save" />
				<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</div>
			<div v-else>
				{{$t('Estatus')}}:
				<Dropdown v-model="opcion" :options="opciones.filter(val => val.code != 1)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="renovacion"></Dropdown>
				<Dropdown v-model="poliza.estado" :options="estados.filter(val => val.code != 3)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-else></Dropdown>
				<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="save" />
				<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</div>
		</template>
	</Dialog>

	<Dialog v-model:visible="Diag" header=" " :style="{width: size}" :modal="true">
        <DataTable :value="[ver_mostrar]" stripedRows responsiveLayout="scroll" v-if="Caso == 1">
            <Column :header="$t('Marca')">
                <template #body="slotProps">
                    {{slotProps.data.marca}}
                </template>
            </Column>
            <Column :header="$t('Módelo')">
                <template #body="slotProps">
                    {{slotProps.data.modelo}}
                </template>
            </Column>
            <Column :header="$t('Nombre Completo')">
                <template #body="slotProps">
                    {{slotProps.data.nombre}}
                </template>
            </Column>
            <Column :header="$t('Certificado')">
                <template #body="slotProps">
					<img style="width: 100px;" :src="url.upload+slotProps.data.veh_adverso"/>
                </template>
            </Column>
        </DataTable>

        <DataTable :value="ver_mostrar" stripedRows responsiveLayout="scroll" v-if="Caso == 2">
            <Column :header="$t('Licencia')">
				<template #body="slotProps">
					{{slotProps.data.categoria}}
				</template>
			</Column>
            <Column :header="$t('Nombre Completo')">
                <template #body="slotProps">
                    {{slotProps.data.nombre}}
                </template>
            </Column>
            <Column :header="$t('Número ID')">
                <template #body="slotProps">
                    {{slotProps.data.code}}
					<Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
                </template>
            </Column>
            <Column :header="$t('Anverso')">
                <template #body="slotProps">
                    <img style="width: 100px;" :src="url.upload+slotProps.data.adverso"/>
                </template>
            </Column>
        </DataTable>
	</Dialog>

	<Dialog v-model:visible="DiagMENS" header=" " :style="{width: size}" :modal="true">
        <br>
        {{mensaje}}
	</Dialog>
	<Void @act-eval="Mostrar" caso="Vehiculo"/>
</div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Evaluacion','Vehiculo');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import Token from "uuid-token-generator";
import { defineAsyncComponent } from "@vue/runtime-core";

export default {
	components: {
		Void: defineAsyncComponent(() => import('../components/Void.vue')),
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            Dialog: false,
            poliza_vehiculo: [],
            poliza: null,
			size: '60vw',
			url: null,
			estados: [],
			noclaims: [],
			descuentos: [],
            duracion: [],
            cuotas: [],
			cuotas_opc: [],
            cuotas1: [],
            cuotas2: [],
            costo: null,
            years: [],
            tarifas: [],
            factor_limite: 0,
            recarga_edadveh: 0,
            recarga_edad: 0,
            recarga_volante: 0,
			premie: 0,
			kosten: 0,
			crs: 0,
			ob: 0,
			impuesto: 0,
			ver_mostrar: null,
			Diag: false,
			Caso: 0,
			imagen: null,
			DiagMENS: false,
			mensaje: null,
			procesar: false,
			renovacion: false,
			codimg: null,
			ver: null,
			calculo: null,
			no_claim: null,
			adicionales: [],
			adicional: [],
			adic: 0,
			opcion: {name: 'Renewal Process', nombre: 'Proceso Renovación', code: 0},
			opciones: [
				{name: 'Renewal Process', nombre: 'Proceso Renovación', code: 0},
				{name: 'Commitment', nombre: 'Compromiso', code: 1},
				{name: 'Reverse', nombre: 'Reversar', code: 2},
			],
			sellers: [],
			reinsurances: [],
			antiquity: 0,
			estimate: 0,
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		

	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.estados = [];
			this.poliza = null;
			this.poliza_vehiculo = [];
            this.Dialogo = false;
			this.duracion = [];
			this.cuotas = [];
			this.cuotas_opc = [];
			this.cuotas1 = [];
            this.cuotas2 = [];
            Consulta.Ini().then(result => {
				//this.$store.state.error=result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				if(result.consult.poliza_vehiculo){
					this.poliza_vehiculo = result.consult.poliza_vehiculo;
				}				
				this.estados = result.consult.estados;
				this.noclaims = result.consult.noclaims;
				this.descuentos = result.consult.descuentos;
                this.duracion = result.consult.duracion;
                this.cuotas = result.consult.cuotas;
                this.kosten = result.consult.valores.costo1;
				this.crs = result.consult.valores.costo2;
				this.impuesto = result.consult.valores.impuesto;
				this.tarifas = result.consult.tarifas;
				this.adicionales = result.consult.adicionales;
				this.sellers = result.consult.sellers;
				this.reinsurances = result.consult.reinsurances;
            }); 
        },
		confirm(data){
			this.$store.state.void_data = data;
			this.$store.state.void = true;
		},
		Ver1(caso,data){
			this.Caso = caso;
			this.ver_mostrar = data;
			this.Diag = true;
		},
		VerMENS(data){
			this.mensaje = data;
			this.DiagMENS = true;
		},
		myUploader(opcion,data) {
			Consulta.Procesar('UPDIMG',{
				foto: data,
				opcion: opcion,
				nuevo: this.codimg
			}).then(response => {
				this.ver = response;
				if(response.result){
					if(opcion==1){
						data.adverso = response.result;
					}
					if(opcion==2){
						this.poliza.veh_adverso = response.result;
					}
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				const tokgen = new Token(256);
				this.codimg = tokgen.generate();
			});
		},
		Duracion(){
			if(this.poliza.duracion){
				this.cuotas_opc = [];
				switch (this.poliza.duracion.code) {
					case 1:
						this.cuotas_opc = this.cuotas.filter(val => val.code<=1);
						break;	
					case 2:
						this.cuotas_opc = this.cuotas.filter(val => val.code==0 || val.code==3);
						break;	
					case 3:
						this.cuotas_opc = this.cuotas.filter(val => val.code==0 || val.code==3);
						break;	
					default:
						this.cuotas_opc = this.cuotas;
				}		
				if(!this.cuotas_opc.includes(this.poliza.cuotas)){
					this.poliza.cuotas = this.cuotas_opc[0];
				}
				this.poliza.evaluacion.kosten = this.poliza.duracion.cost;
			}	
		},
		Revisado(poliza) {
			Consulta.Procesar('Revisado',{
				poliza: poliza,
			}).then(response => {
				//this.$store.state.error=response;
				if(response.result){
					this.Mostrar();		
				}				
            }); 
		},
		EditPoliza(poliza) {
			this.estimate = 0;
			this.antiquity = 0;
			this.adjustment = 0;
			this.procesar = false;
			const tokgen = new Token(256);
			this.codimg = tokgen.generate();
			this.poliza = null;
			this.years = [];
			this.no_claim = null;
			this.adicional = [];
			this.poliza = {...poliza};
			Consulta.Procesar('Ver',{
				poliza: poliza,
			}).then(response => {
				//this.$store.state.error=response;
				this.antiquity = response.result.antiquity;
				this.adjustment = response.result.adjustment;
				this.no_claim = response.result.no_claim;
				this.years = response.result.years;
				this.poliza.veh_ano = response.result.years.filter(val => val.code == poliza.vehano)[0];
				this.poliza.tarifa = this.tarifas.filter(val => val.code == poliza.evaluacion.poliza_valor)[0];
				if(poliza.estado==7){
					this.renovacion = true;
				} else {
					this.renovacion = false;
					this.poliza.estado = this.estados.filter(val => val.code == this.poliza.estado)[0];
				}
				this.poliza.noclaim = this.noclaims.filter(val => val.code == this.poliza.evaluacion.noclaim_id)[0];
				this.poliza.descuento = this.descuentos.filter(val => val.code == this.poliza.evaluacion.descuento)[0];
				this.poliza.duracion = this.duracion.filter(val => val.code == this.poliza.duracion)[0];
				this.poliza.cuotas = this.cuotas.filter(val => val.code == this.poliza.cuotas)[0];
				if(this.poliza.evaluacion.reinsurance_id){
					this.poliza.evaluacion.reinsurance_id = this.reinsurances.filter(val => val.id == this.poliza.evaluacion.reinsurance_id)[0];
				}
				if(this.poliza.reseller_id){
					this.poliza.reseller_id = this.sellers.filter(val => val.seller == this.poliza.reseller_id && val.tipopoliza == this.poliza.tipopoliza)[0];
				}
				this.adicional = poliza.adicional;
				this.Duracion();
				this.Calcular();
				this.Dialog = true;
            }); 
		},
		Adicional(){
			var adicional = 0;
			this.adicional.forEach(element => {
				adicional = adicional + parseFloat(element.valor);
			});
			this.adic = adicional;
		},
		Estimate() {
			if(this.calculo){
				let monto = ((parseFloat(this.calculo.subtotal*(1+this.no_claim.factor-this.poliza.descuento.valor)+this.adic))*(1+this.poliza.cuotas.porcentaje/100)*this.poliza.duracion.valor+parseFloat(this.poliza.evaluacion.kosten))*(1+this.calculo.impuesto);
				if(this.estimate>0 && this.estimate!=monto){
					let adjustment = 0;
					let T = parseFloat(this.estimate);
					let C = parseFloat(this.poliza.evaluacion.kosten);
					let Im = parseFloat(this.calculo.impuesto);
					let R = parseFloat(this.poliza.cuotas.porcentaje/100);
					let D = parseFloat(this.poliza.duracion.valor);
					let A = parseFloat(this.adic);
					let fa = parseFloat(this.calculo.adic_asientos);
					let G = parseFloat(this.calculo.primabruta);	
					let P_E = parseFloat(this.calculo.recarga_edad)/parseFloat(this.calculo.primabruta);
					let P_Ve = parseFloat(this.calculo.recarga_veh)/parseFloat(this.calculo.primabruta);
					let P_Vo = parseFloat(this.calculo.recarga_volante)/parseFloat(this.calculo.primabruta);
					let NC = parseFloat(this.no_claim.factor);
					let De = parseFloat(this.poliza.descuento.valor);
					adjustment = T / ( 1 + Im ) ;
					adjustment = adjustment - C ;
					adjustment = adjustment / ( ( 1 + R ) * D ) ;
					adjustment = adjustment - A ;
					adjustment = adjustment / ( 1 - De + NC );
					adjustment = adjustment - fa;
					adjustment = adjustment / ( 1 + P_E + P_Ve + P_Vo );
					adjustment = adjustment - G;
					if(parseFloat(this.estimate).toFixed(1) != parseFloat(monto).toFixed(1)){
						this.poliza.evaluacion.adjustment = adjustment;
					}
				} else { 
					this.estimate == 0;
					this.poliza.evaluacion.adjustment = 0;
				}
				
			}
			this.Calcular();
		},
		Calc_NoClaim() {
			Consulta.Procesar('Calc_NoClaim',{
				poliza: this.poliza,
				antiquity: this.antiquity,
			}).then(response => {
				//this.$store.state.error=response;
				if(response){
					this.no_claim = response.result;
					this.Estimate();
				}
            }); 
		},
		Calcular() {
			this.Adicional();
			this.calculo = null;
			Consulta.Procesar('Calcular',{
				poliza: this.poliza,
				adjustment: this.poliza.evaluacion.adjustment,
			}).then(response => {
				//this.$store.state.error=response;
				if(response){
					this.calculo = response.result;
				}
            }); 
		},
        Ver(data){
			//this.$store.state.error = data.evaluacion.cuotas;
            const doc = new PDF();
			var monto = ((data.evaluacion.primabrutat*this.duracion[data.duracion].valor*(1+this.cuotas[data.cuotas].porcentaje/100)+parseFloat(data.evaluacion.kosten))*(1+parseFloat(data.evaluacion.impuesto)/100));
            doc.Cotizacion(2,data,this.duracion,this.cuotas,monto);
        },
		save() {
			if (this.poliza.evaluacion.tipopoliza == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Tipo de Póliza'), life: 10000});
			} else if (this.poliza.evaluacion.valorcatalogo == 0) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Valor Catálogo'), life: 10000});
			} else {
				Consulta.Procesar('UPD',{
					id: this.poliza.id,
					estado: this.poliza.estado,
					valorcatalogo: this.calculo.catalogo_vehiculo,
					poliza_valor: this.poliza.tarifa.code,
					primabruta: this.calculo.primabruta,
					comentario: this.poliza.evaluacion.comentario,
					limite: this.calculo.limite,
					factorlimite: this.calculo.factorlimite,
					factor: this.calculo.factor,
					recarga_edad: this.calculo.recarga_edad,
					recarga_veh: this.calculo.recarga_veh,
					recarga_volante: this.calculo.recarga_volante,
					kosten: this.poliza.evaluacion.kosten,
					ob: ((parseFloat(this.calculo.subtotal*(1+this.no_claim.factor-this.poliza.descuento.valor)+this.adic))*(1+this.poliza.cuotas.porcentaje/100)*this.poliza.duracion.valor+parseFloat(this.poliza.evaluacion.kosten))*(this.calculo.impuesto),
					noclaim_factor: this.no_claim.factor,
					descuento: this.poliza.descuento.code,
					duracion: this.poliza.duracion,
					cuotas: this.poliza.cuotas,
					renovacion: this.renovacion,
					opcion: this.opcion,
					usuario: this.poliza.usuario,
					exoneracion: this.poliza.exoneracion,
					leng: this.i18n.locale(),
					calculo: this.calculo,
					broker:parseFloat(this.poliza.evaluacion.borker_porc_act),
					broker_monto: (((parseFloat(this.calculo.subtotal*(1+this.no_claim.factor-this.poliza.descuento.valor)+this.adic))*(1+this.poliza.cuotas.porcentaje/100)*this.poliza.duracion.valor)*this.poliza.evaluacion.borker_porc_act/100),
					deductible:  this.calculo.deductible,
					adic_asientos: parseFloat(this.calculo.adic_asientos),
					crs: this.crs,
					no_claim: this.no_claim.step,
					adic: this.adic,
					adicional: this.adicional,
					reinsurance: this.poliza.evaluacion.reinsurance_id,
					reseller: this.poliza.reseller_id,
					antiquity: this.antiquity,
					adjustment: this.poliza.evaluacion.adjustment,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
					this.Dialog = false;
					this.poliza = null;
				});
			}
		},
		toggle(event) {
			this.$refs.op.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		IMG(imegan){
			this.imagen = imegan;
			this.toggle1(event);
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
